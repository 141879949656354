<template>
    <modal ref="refOpenModal" :titulo="`Usuarios seleccionados: ${clientes.length} `" no-aceptar>
        <div class="row mx-0">
            <p class="col-12 f-16 fr-medium text-general">Nombre</p>
			<!-- list users -->
			<div class="col-12 d-middle text-general border-bottom my-1" v-for="(item,index) in clientes" :key="index">
				<p class="f-16 fr-light">{{ item.nombre }}</p>
				<i class="icon-account-remove ml-auto f-18 cr-pointer" @click="eliminateUser(item.id_user)"/>
			</div>
			<!-- load more users -->
			<div class="col-auto f-14 text-general mt-2">
				<div class="cr-pointer" @click="loadMoreUsers">
                    <span v-if="clientes.length === 0">No hay usuarios seleccionados</span>
				</div>
			</div>
        </div>
    </modal>
</template>

<script>
// import Cupones from '~/services/cupones/cupones'
export default {
	props: {
        clientes: {
            type: Array,
            default: []
        }
    },
    data(){
        return{}
    },
    methods: {
        toggle(){
            this.$refs.refOpenModal.toggle();
        },
        loadMoreUsers(){
        },
		eliminateUser(id){
			this.$emit('remove', id)
		}
    }
}
</script>
