<template>
    <modal-lateral ref="refOpenModal" titulo="Filtro">
        <div class="row mx-0">
            <div class="col-12 mb-3">
                <p class="f-12 text-general pl-2">Nombre</p>
                <el-input v-model="payload.nombre" placeholder="Buscar" size="small" />
            </div>
            <div class="col-12 mb-3">
                <p class="f-12 text-general pl-2">N°. Identificacion</p>
                <el-input v-model="payload.identificacion" placeholder="Buscar" size="small" />
            </div>
            <div class="col-12 mb-3">
                <p class="f-12 text-general pl-2">Ciudad</p>
                <el-select v-model="payload.idCiudad" placeholder="Buscar" filterable clearable size="small" class="w-100">
                    <el-option v-for="item in ciudades" :key="item.id" :label="item.ciudad" :value="item.id" />
                </el-select>
            </div>
            <div class="col-12 mb-3">
                <p class="f-12 text-general pl-2">Género</p>
                <el-select v-model="payload.idGenero" placeholder="Género" size="small" class="w-100" clearable>
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <p class="col-12 f-12 text-general mt-3">Edad</p>
            <div class="col-6">
                <el-input v-model="payload.edadMin" placeholder="Desde" size="small" type="number" />
            </div>
            <div class="col-6">
                <el-input v-model="payload.edadMax" placeholder="Hasta" size="small" type="number" />
            </div>
            <p class="col-12 f-12 text-general mt-3">Compras</p>
            <div class="col-6">
                <el-input v-model="payload.comprasMin" placeholder="Desde" size="small" type="number" />
            </div>
            <div class="col-6">
                <el-input v-model="payload.comprasMax" placeholder="Hasta" size="small" type="number" />
            </div>
            <p class="col-12 f-12 text-general mt-3">Valor última compra</p>
            <div class="col-6">
                <el-input v-model="payload.ultimaCompraMin" placeholder="Desde" size="small" :controls="false"
                          type="number"
                />
            </div>
            <div class="col-6">
                <el-input v-model="payload.ultimaCompraMax" placeholder="Hasta" size="small" :controls="false"
                          type="number"
                /> <!-- Aquì voy -->
            </div>
            <div class="col-12 mt-3">
                <p class="f-12 text-general pl-2">Fecha última compra</p>
                <el-date-picker v-model="payload.fechaUltimaCompra" type="daterange" size="small" range-separator="-"
                                start-placeholder="Fecha inicio" end-placeholder="Fecha fin"
                />
            </div>
            <div class="col-12 mb-3">
                <p class="f-12 text-general pl-2">Productos en carrito</p>
                <el-select v-model="payload.cantProductos" placeholder="" size="small" class="w-100" clearable>
                    <el-option v-for="item in optionsCarrito" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <div class="col-12 my-3">
                <p class="f-12 text-general pl-2">Vendedor principal</p>
                <el-input v-model="payload.vendedor" placeholder="Buscar" size="small" />
            </div>
            <p class="col-12 f-12 text-general mt-3">Antiguedad (Meses)</p>
            <!-- <div class="col-12 mt-3"> -->
            <div class="col-6">
                <el-input v-model="payload.antiguedadMin" placeholder="Desde" size="small" type="number" />
            </div>
            <div class="col-6">
                <el-input v-model="payload.antiguedadMax" placeholder="Hasta" size="small" type="number" />
            </div>
            <!-- </div> -->
            <p class="col-12 f-12 text-general mt-3">Calificaciones</p>
            <div class="col-6">
                <el-input v-model="payload.calificacionesMin" placeholder="Desde" size="small" type="number" />
            </div>
            <div class="col-6">
                <el-input v-model="payload.calificacionesMax" placeholder="Hasta" size="small" type="number" />
            </div>
            <p class="col-12 f-12 text-general mt-3">Calificaciones promedio</p>
            <div class="col-6">
                <el-input v-model="payload.promedioMin" placeholder="Desde" size="small" type="number" :min="1" :max="5" />
            </div>
            <div class="col-6">
                <el-input v-model="payload.promedioMax" placeholder="Hasta" size="small" type="number" :min="1" :max="5" />
            </div>
            <p class="col-12 f-12 text-general mt-3">Últ. acceso (Días)</p>
            <div class="col-6">
                <el-input v-model="payload.diasAccesosMin" placeholder="Desde" size="small" />
            </div>
            <div class="col-6">
                <el-input v-model="payload.diasAccesoMax" placeholder="Hasta" size="small" />
            </div>
            <p class="col-12 f-12 text-general mt-3"># de accesos</p>
            <div class="col-6">
                <el-input v-model="payload.cantAccesosMin" placeholder="Desde" size="small" />
            </div>
            <div class="col-6 mb-5">
                <el-input v-model="payload.cantAccesosMax" placeholder="Hasta" size="small" />
            </div>
        </div>
        <div class="fotter-buttons d-middle-center mt-3 w-100 position-absolute" style="bottom: 13px;">
            <button class="w-120px h-36px f-14 text-general mx-2 bg-white border br-5" @click="cleareableFilter">
                Limpiar
            </button>
            <button class="w-120px h-36px f-14 bg-general text-white mx-2 border-0 br-5"
                    @click="actionFilter"
            >
                Filtrar
            </button>
        </div>
    </modal-lateral>
</template>

<script>
// import Cupones from '~/services/cupones/cupones'

import Localizaciones from "~/services/proyectos/localizaciones";
export default {
    data(){
        return {
            ciudades: [],
            options: [
                {
                    value: 2,
                    label: 'Hombre'
                },
                {
                    value: 3,
                    label: 'Mujer'
                },
                {
                    value: 1,
                    label: 'Otro'
                },
            ],
            optionsCarrito: [
                {
                    value: 1,
                    label: 'Si'
                },
                {
                    value: 2,
                    label: 'No'
                },
            ],
            value: '',
            payload: {
                ultimaCompraMin: null,
                ultimaCompraMax: null,
                nombre: '',
                identificacion: '',
                idCiudad: null,
                idGenero: null,
                edadMin: null,
                edadMax: null,
                comprasMin: null,
                comprasMax: null,
                fechaUltimaCompra: '',
                cantProductos: null,
                vendedor: '',
                antiguedadMin: null,
                antiguedadMax: null,
                calificacionesMin: null,
                calificacionesMax: null,
                promedioMin: null,
                promedioMin: null,
                diasAccesosMin: null,
                diasAccesoMax: null,
                cantAccesosMin: null,
                cantAccesosMax: null,
            }
        }
    },
    methods: {
        toggle(){
            if (this.ciudades.length == 0) this.consultarCiudades();
            this.$refs.refOpenModal.toggle();
        },
        actionFilter(){
            this.$emit('filterAction', this.payload)
            this.$refs.refOpenModal.toggle();
        },
        cleareableFilter(){
            this.payload = {
                ultimaCompraMin: null,
                ultimaCompraMax: null,
                nombre: '',
                identificacion: '',
                idCiudad: null,
                idGenero: null,
                edadMin: null,
                edadMax: null,
                comprasMin: null,
                comprasMax: null,
                fechaUltimaCompra: '',
                cantProductos: null,
                vendedor: '',
                antiguedadMin: null,
                antiguedadMax: null,
                calificacionesMin: null,
                calificacionesMax: null,
                promedioMin: null,
                promedioMin: null,
                diasAccesosMin: null,
                diasAccesoMax: null,
                cantAccesosMin: null,
                cantAccesosMax: null,
            }
        },
        async consultarCiudades(){
            try {
                const { data } = await Localizaciones.listarCiudades()
                this.ciudades = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>
